// Form validation
export function handleFormValidation() {
  (function () {
    'use strict';

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    let forms = document.querySelectorAll('.needs-validation');

    if (!forms.length) return

    // Set checkboxGroups valid on change
    let checkboxGroups = document.querySelectorAll('.checkbox-group');
    checkboxGroups.forEach(singleGroup => {
      let singleGroupCheckboxes = singleGroup.querySelectorAll('input[type="checkbox"]');
      singleGroupCheckboxes.forEach(singleInput => {
        singleInput.addEventListener('change', function () {
          singleGroupCheckboxes.forEach(singleInput => {
            singleInput.setCustomValidity('');
          });
        });
      });
    });

    // On submit, Loop over them and prevent submission
    Array.prototype.slice.call(forms)
      .forEach(function (form) {
        form.addEventListener('submit', function (event) {

          // Checkbox groups
          let checkboxContainersSelected = true;
          checkboxGroups.forEach(function (singleGroup) {
            let singleGroupSelected = false;
            let singleGroupCheckboxes = singleGroup.querySelectorAll('input[type="checkbox"]');
            singleGroupCheckboxes.forEach(checkbox => {
              if (checkbox.checked) {
                singleGroupSelected = true;
              }
            });
            if (!singleGroupSelected) {
              checkboxContainersSelected = false;
              singleGroupCheckboxes.forEach(checkbox => {
                checkbox.setCustomValidity('Error');
              });
            } else {
              singleGroupCheckboxes.forEach(checkbox => {
                checkbox.setCustomValidity('');
              });
            }
          });

          // Recaptcha
          let reCaptcha;
          let FormCaptcha = document.querySelector('#g-recaptcha');
          if (event.target.classList.contains('needs-validation') && FormCaptcha) {
            // eslint-disable-next-line no-undef
            if ( grecaptcha.getResponse(renderForm) === '' ) {
              reCaptcha = false;
              event.target.querySelector('#g-recaptcha').classList.add('captcha-error');
            } else {
              reCaptcha = true;
            }
          }else {
            reCaptcha = true;
          }

          if (!form.checkValidity() || !checkboxContainersSelected || !reCaptcha) {
            event.preventDefault();
            event.stopPropagation();
          }

          form.classList.add('was-validated');
        }, false);
      });
  })();

  // Recaptcha callback
  let renderForm;
  let FormCaptcha = document.querySelector('#g-recaptcha');
  window.CaptchaCallback = function() {
    if ( FormCaptcha ) {
      // eslint-disable-next-line no-undef
      renderForm = grecaptcha.render('g-recaptcha', {'sitekey' : FormCaptcha.dataset.sitekey, 'callback' : correctCaptcha});
    }
  };

  let correctCaptcha = function(response) {
    if ( response !== '')
      FormCaptcha.classList.remove('captcha-error');
  };
}
