import {observeElementInViewport} from "../utilities/observe";

export function handleHistory() {
  let historyElem = document.querySelectorAll('.history-entry');

  if (!historyElem.length) return

  historyElem.forEach(elem => {
    observeElementInViewport(elem, (isVisible) => {
      if (isVisible) {
        elem.classList.add('revealed');
      } else {
        // elem.classList.remove('revealed');
      }
    }, { once: true });
  });
}
