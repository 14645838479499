import {WpCurrentClasses} from "../utilities/contsts";
import Collapse from 'bootstrap/js/dist/collapse';

// Expanders (sidebar, sitemap, mobile menu)
function handleExpanders() {
  const expanders = document.querySelectorAll('.expander');

  if (!expanders.length) return

  Array.from(expanders).map(expander => {
    let parent = expander.parentElement;

    if (parent.classList.contains('menu-item-has-children') && parent.querySelector('a')) {
      parent.querySelector('a').setAttribute('aria-expanded', false);
      parent.querySelector('a').setAttribute('aria-haspopup', true);
    }

    if (WpCurrentClasses.some(ai => Array.from(parent.classList).includes(ai))) {
      parent.querySelector('a').setAttribute('aria-expanded', true);
    }

    expander.addEventListener('click', function () {
      if (WpCurrentClasses.some(ai => Array.from(parent.classList).includes(ai))) {
        for (let i = 0; i < WpCurrentClasses.length; i++) {
          parent.classList.remove(WpCurrentClasses[i]);
          parent.querySelector('a').setAttribute('aria-expanded', false);
        }
      } else {
        parent.classList.toggle('open');

        if (parent.classList.contains('open'))
          parent.querySelector('a').setAttribute('aria-expanded', true);
        else
          parent.querySelector('a').setAttribute('aria-expanded', false);
      }
    })
  })
}

// Menu
function handleMenu() {
  const mainMenuItems = document.querySelectorAll('#navbarNav .navbar-nav > .menu-item')

  if (!mainMenuItems) {
    return
  }

  Array.from(mainMenuItems).map(menuItem => {

    function notEexpanded() {
      let navCollapseElement = document.querySelector('#navbarNav');
      let navCollapseInstance = Collapse.getInstance(navCollapseElement);
      if (navCollapseInstance) {
        navCollapseInstance.hide();
      }
    }

    if (menuItem) {
      document.querySelectorAll('.btn-search').forEach(searchBtn => {
        searchBtn.addEventListener('mouseenter', notEexpanded, false);
      })

    }

    document.addEventListener('keydown', function (e) {
      const code = e.key;
      if (code === 'Escape') {
        notEexpanded()
      }
    });
  })

  // Close megamenu
  const closeMegamenu = () => {
    document.addEventListener('click', function (event) {
      let navCollapseElement = document.querySelector('#navbarNav');
      let navCollapseInstance = Collapse.getInstance(navCollapseElement);

      if (navCollapseInstance) {
        if (!event.target.matches('[data-bs-toggle="collapse"]') && !event.target.closest('.expander') && !event.target.closest('.search-field')) {
          navCollapseInstance.hide();
        }
      }
    });
  }

  closeMegamenu()

  // Add current class on posts
  const currentClass = () => {
    if (!document.body.classList.contains('single')) {
      return
    }

    const menuItems = document.querySelectorAll('.current-menu-item');
    Array.from(menuItems).map(item => {
      if (item.closest('.menu-item-has-children'))
        item.closest('.menu-item-has-children').classList.add('current-menu-item')
    })
  }
  currentClass()

  // Toggle mobile-menu-visible class in body
  const mobileMenuVisible = () => {
    const navbarToggler = document.getElementById('navbarNav');
    const rwdSearchToggler = document.querySelector('.btn-search[data-bs-target="#navbarNav"]');
    const rwdSearch = document.querySelector('.mobile-search input');
    let triggeredBySearchBtn = false;

    if (!navbarToggler) {
      return
    }

    rwdSearchToggler.addEventListener('click', function () {
      triggeredBySearchBtn = true;
    })

    // Toggle mobile-menu-visible class in body
    navbarToggler.addEventListener('show.bs.collapse', function () {
      document.body.classList.add('mobile-menu-visible');
      setTimeout(() => {
        if (triggeredBySearchBtn === true) {
          rwdSearch.focus();
        }
      }, 0);
    });

    navbarToggler.addEventListener('hide.bs.collapse', function () {
      document.body.classList.remove('mobile-menu-visible');
      triggeredBySearchBtn = false;
    });
  }
  mobileMenuVisible()

}

export { handleExpanders, handleMenu }
