import Tooltip from 'bootstrap/js/dist/tooltip';

export function handleTooltips() {
  let tooltipTriggerList = [].slice.call(document.querySelectorAll('table [data-extra], .module-edit-link[data-extra]'))

  if (!tooltipTriggerList.length) return

  tooltipTriggerList.map(function (tooltipTriggerEl) {
    new Tooltip(tooltipTriggerEl, {
      title: tooltipTriggerEl.dataset.extra,
    })
  })
}
