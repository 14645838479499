function handleButtons() {
  // Module edit links
  const moduleEditLink = () => {
    const moduleEditLink = document.querySelectorAll('.module-edit-link');

    if (!moduleEditLink) return

    moduleEditLink.forEach(link => {
      link.parentElement.addEventListener('mouseover', function () {
        link.parentElement.classList.add('module-edit-hover');
      });
      link.parentElement.addEventListener('mouseout', function () {
        link.parentElement.classList.remove('module-edit-hover');
      });
    });
  }
  moduleEditLink()
}

export { handleButtons }
