import {styles} from "./map-styles";
const google = window.google;

export function handleMap() {
  function initMap(el) {

    // Find marker elements within map.
    let $markers = el.querySelectorAll('.marker');

    // Create gerenic map.
    let mapArgs = {
      zoom: parseInt(el.dataset.zoom) || 16,
      styles: styles,
      disableDefaultUI: true, //hide controls
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };
    let map = new google.maps.Map(el, mapArgs);

    // Add markers.
    map.markers = [];

    $markers.forEach(marker => {
      initMarker(marker, map);
    })

    // Center map based on markers.
    centerMap(map);

    // Return map instance.
    return map;
  }

  function initMarker($marker, map) {

    // Get position from marker.
    let lat = $marker.dataset.lat;
    let lng = $marker.dataset.lng;
    let latLng = {
      lat: parseFloat(lat),
      lng: parseFloat(lng),
    };

    // Create marker instance.
    let marker = new google.maps.Marker({
      position: latLng,
      map: map,
    });

    // Append to reference for later use.
    map.markers.push(marker);

    // If marker contains HTML, add it to an infoWindow.
    if ($marker.innerHTML) {

      // Create info window.
      let infowindow = new google.maps.InfoWindow({
        content: $marker.innerHTML,
      });

      // Show info window when marker is clicked.
      google.maps.event.addListener(marker, 'click', function () {
        infowindow.open(map, marker);
      });
    }
  }

  // Center map based on markers.
  function centerMap(map) {
    // Create map boundaries from all map markers.
    let bounds = new google.maps.LatLngBounds();
    map.markers.forEach(function (marker) {
      bounds.extend({
        lat: marker.position.lat(),
        lng: marker.position.lng(),
      });
    });

    // Case: Single marker.
    if (map.markers.length === 1) {
      map.setCenter(bounds.getCenter());

      // Case: Multiple markers.
    } else {
      map.fitBounds(bounds);
    }
  }

  document.addEventListener("DOMContentLoaded", function () {
    const maps = document.querySelectorAll('.acf-map')

    if (!maps.length) return

    maps.forEach(map => {
      initMap(map)
    })
  });
}
