import { CountUp } from 'countup.js';
import CheckLang from '../utilities/check-lang';
import {observeElementInViewport} from "../utilities/observe";

export function handleNumbers() {
  let numberElements = document.querySelectorAll('.module-numbers .numbers li');

  if (!numberElements.length) return

  let options = {
    decimalPlaces: 0,
    duration: 2,
    separator: ',',
    decimal: '.',
  };
  // var counterSpansDelay = 0;

  if (CheckLang('sv')) {
    options.separator = ' ';
    options.decimal = ',';
  }

  const animateSingleNumber = (el) => {
    if (!el) return

    options.decimalPlaces = 0;
    if (el.dataset.count % 1 !== 0) {
      let radixPos = String(el.dataset.count).indexOf('.') + 1;
      let value = String(el.dataset.count).slice(radixPos);
      options.decimalPlaces = value.length;
    }
    let numAnim = new CountUp(el.getAttribute('id'), parseFloat(el.dataset.count), options);
    numAnim.start();
  }

  numberElements.forEach((el) => {
    observeElementInViewport(el, (isVisible) => {
      if (isVisible) {
        animateSingleNumber(el.querySelector('.number'))
      }
    }, { once: true });
  })
}
