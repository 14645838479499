// Bootstrap (importing BS scripts individually)
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/modal';

// Components
// import {handleLiveSearch} from "./components/search/live-search";
// import {handlePredictedSearch} from "./components/search/predicted-search";
// import {handleAccessibility} from "./components/accessibility";
// import {handleSlider} from "./modules/slider";
// import {handleColorModeToggler} from "./components/color-mode-toggler";
// import './react/index'
import {handleFancybox} from "./components/fancybox";
import {handleMenu, handleExpanders} from "./components/menu";
import {handleTables} from "./components/tables";
import {handleSearch} from "./components/search/search";
import {handleButtons} from "./components/buttons";
import {handleDropdowns} from "./components/dropdowns";
import {handlePeople} from "./components/contacts";
import {handleFormValidation} from "./components/forms";
import {handleTooltips} from "./components/tooltips";
import {handleNumbers} from "./modules/numbers";
import {handlePipeline} from "./modules/pipeline";
import {handleMap} from "./modules/map/map";
import {handleSharetickerCision} from "./modules/shareticker-cision";
import {handleSharetickerMfn} from "./modules/shareticker-mfn";
import {handleDisclaimer} from "./special/disclaimer";
import {handleHistory} from "./special/history";
import {handleProjects} from "./special/projects";
import {handleScrollingMenu} from "./special/scrolling-menu";
import {handleReleasesFilters} from "./special/releases-filters";
import {handleImagebankFilters} from "./special/imagebank-filters";
import {handleReportsFilters} from "./special/reports-filters";
import {handleCheckScroll} from "./utilities/check-scroll";
import {handleNextSection} from "./utilities/next-section";
import {handlePipelinev2} from "./modules/pipelinev2";
import {handleMiniSlider} from "./modules/mini-slider";
import {handlePortfolio} from "./modules/portfolio-slider";
import {handleBubbles} from "./special/bubbles";

const mountedFns = [
  // handleLiveSearch,
  // handlePredictedSearch,
  // handleAccessibility,
  // handleSlider,
  // handleColorModeToggler,
  handleFancybox,
  handleMenu,
  handleExpanders,
  handleTables,
  handleSearch,
  handleButtons,
  handlePeople,
  handleFormValidation,
  handleTooltips,
  handleMiniSlider,
  handlePortfolio,
  handleNumbers,
  handlePipeline,
  handlePipelinev2,
  handleMap,
  handleSharetickerCision,
  handleSharetickerMfn,
  handleDisclaimer,
  handleHistory,
  handleProjects,
  handleScrollingMenu,
  handleReleasesFilters,
  handleImagebankFilters,
  handleReportsFilters,
  handleDropdowns,
  handleCheckScroll,
  handleNextSection,
  handleBubbles,
]

// Run fn-s
for (const demountFn of mountedFns) {
  typeof demountFn === 'function' && demountFn()
}

