import Collapse from 'bootstrap/js/dist/collapse';

const navbarToggler = document.getElementById('navbarNav');
const searchForm = document.querySelectorAll('.toggle-search-form');

function closeSearch() {
  if (!searchForm.length) return

  Array.from(searchForm).map(form => {
    let searchInstance = new Collapse(form, { toggle: false });
    searchInstance.hide();
  });
}

function handleSearch() {
  if (searchForm.length && navbarToggler) {
    const togglerInstance = new Collapse(navbarToggler, { toggle: false });

    Array.from(searchForm).map(form => {
      let searchInstance = new Collapse(form, { toggle: false });
      // Hide RWD menu when click search, focus on search
      form.addEventListener('show.bs.collapse', function () {
        togglerInstance.hide();
        setTimeout(function () {
          form.querySelector('.search-field').focus()
        }, 0)
      });

      // Hide search on body click
      document.addEventListener('click', (e) => {
        if (!form.contains(e.target)) {
          searchInstance.hide();
        }
      });

      document.addEventListener('keydown', function (e) {
        const code = e.key;
        if (code === 'Escape') {
          searchInstance.hide();
        }
      });
    });
  }
}

export { handleSearch, closeSearch }
