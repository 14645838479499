import Swiper from 'swiper/bundle';
export function handleMiniSlider(){
  const miniSlider = document.querySelector('.module-slidersmall');

  if(miniSlider && miniSlider.querySelectorAll('.swiper-slide').length > 1){
    new Swiper('.module-slidersmall .swiper', {
      speed: 700,
      spaceBetween: 90,
      navigation: {
        nextEl: '.module-slidersmall .swiper-button-next',
        prevEl: '.module-slidersmall .swiper-button-prev',
      },
      breakpoints: {
        0: {
          slidesPerView: 1.13,
          spaceBetween: 15,
          autoHeight: true,
        },
        993: {
          slidesPerView: 2,
        },
      },
    });
  }
}
