export function handleBubbles() {
  let bubbleElem = document.querySelectorAll('.circles-view .links-row-content');

  if (!bubbleElem.length) return

  bubbleElem.forEach((bubble) => {
    bubble.addEventListener('click', function() {
      let closestBubble = this.closest('.links-row-content');
      closestBubble.classList.add('show');
    });

    bubble.addEventListener('mouseleave', function() {
      let closestBubble = this.closest('.links-row-content');
      if (closestBubble.classList.contains('show')) {
        closestBubble.classList.remove('show');   
      }
    });
  })
}

