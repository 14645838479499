export function observeElementInViewport(el, callback, { once = true } = {}) {
  let hasBeenCalled = false; // Flag to track if the callback has been called

  const onIntersection = (entries, observer) => {
    entries.forEach(entry => {
      // Check if the element is in the viewport
      if (entry.isIntersecting) {
        // If "once" is true and the callback has already been called, do nothing
        if (once && hasBeenCalled) return;

        // Set the flag to true as we're calling the callback
        hasBeenCalled = true;

        // Call the callback with true to indicate visibility
        callback(true, entry.target);

        if (once) observer.disconnect();
      } else {
        if (!once) callback(false, entry.target);
      }
    });
  };

  const options = {
    root: null, // observing interactions with the viewport
    threshold: 0.4, // Trigger callback when 10% of the element is visible
  };

  const observer = new IntersectionObserver(onIntersection, options);
  observer.observe(el);

  return observer;
}

// Example usage
// observeElementInViewport('some_element', (isVisible) => {
//   if (isVisible) {
//     animate();
//   }
// }, { once: true });
