import {Fancybox} from '@fancyapps/ui';

export function handleFancybox() {

  // Single
  const handleSingleFancyItems = () => {
    const singleFancyItems = ['a[href$=".jpg"]:not(.no-fancy)', 'a[href$=".jpeg"]:not(.no-fancy)', 'a[href$=".png"]:not(.no-fancy)', 'a[href$=".webp"]:not(.no-fancy)', 'a[href$=".svg"]:not(.no-fancy)', '.fancyimage', '.fancyvideo'];

    if (!singleFancyItems.length) return

    singleFancyItems.forEach(function (value) {
      Fancybox.bind(value, {
        Toolbar: {
          display: {
            left: [],
            middle: [],
            right: ["close"],
          },
        },
      });
    });
  }
  handleSingleFancyItems()

  // Gallery
  const handleGalleryFancyItems = () => {
    const galleryFancyItems = ['.gallery-item a:not(.no-fancy)', '.related-images a:not(.no-fancy)', '.imagebank-list a:not(.no-fancy)', '.wp-block-gallery a'];

    if (!galleryFancyItems.length) {
      return
    }

    galleryFancyItems.forEach(function (value) {
      Fancybox.bind(value, {
        groupAll: true,
        Toolbar: {
          display: {
            left: [],
            middle: [],
            right: ["close"],
          },
        },
        caption: function (fancybox, slide) {
          let title = slide.title || '';
          let caption;
          let description = slide.description || '';
          let hq = slide.hq || '';
          let lq = slide.lq || '';
          let hqText = slide.hqText || '';
          let lqText = slide.lqText || '';
          let hqSize = slide.hqSize || '';
          let lqSize = slide.lqSize || '';
          let downloadIcon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M528 288h-92.1l46.1-46.1c30.1-30.1 8.8-81.9-33.9-81.9h-64V48a48 48 0 0 0-48-48h-96a48 48 0 0 0-48 48v112h-64c-42.6 0-64.2 51.7-33.9 81.9l46.1 46.1H48a48 48 0 0 0-48 48v128a48 48 0 0 0 48 48h480a48 48 0 0 0 48-48V336a48 48 0 0 0-48-48zm-400-80h112V48h96v160h112L288 368zm400 256H48V336h140.1l65.9 65.9a48 48 0 0 0 67.9 0l65.9-65.9H528zm-88-64a24 24 0 1 1 24 24 23.9 23.9 0 0 1-24-24z"/></svg>';

          if (slide.type === 'image') {
            caption = (title.length ? '<div class="title mb-1">' + title + '</div>' : '') +
              (description.length ? '<div class="description">' + description + '</div>' : '');

            if (hq || lq) {
              caption += '<div class="download-buttons">' +
                (hq ? '<a class="download-link high-res no-fancy" href="' + hq + '" download>' + downloadIcon + hqText + ' (' + hqSize + ')' + '</a>' : '') +
                (lq ? '<a class="download-link low-res no-fancy" href="' + lq + '" download>' + downloadIcon + lqText + ' (' + lqSize + ')' + '</a>' : '') +
                '</div>';
            }
          }

          return caption;
        },
      });
    });
  }
  handleGalleryFancyItems()

  // Module edit fancybox
  const handleModuleEdit = () => {
    if (!document.querySelector('.module-edit-link')) {
      return
    }

    Fancybox.bind(".module-edit-link", {
      defaultType: "iframe",
      mainClass: 'module-edit-fancy',
      Toolbar: {
        display: {
          left: [],
          middle: [],
          right: ["close"],
        },
      },
      on: {
        ready: () => {
          window.Fancybox = Fancybox
          window.cwFancyboxInit = 0
        },
        done: (fancybox, current) => {
          const triggerBtn = fancybox?.options?.triggerEl

          if (triggerBtn && triggerBtn.classList.contains('module-edit-link-row')) {
            const iframe = current.iframeEl;
            const elementId = 'row-id-' + triggerBtn.dataset.anchor;
            const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

            if (iframeDocument.querySelector(".acf-tab[data-order='layout']")) {
              iframeDocument.querySelector(".acf-tab[data-order='layout']").click()
            }

            if (iframeDocument.querySelector('.auto-fold .interface-interface-skeleton')) {
              iframeDocument.querySelector('.auto-fold .interface-interface-skeleton').style.left = 0;
            }

            // Options for the observer (which mutations to observe)
            const config = {childList: true, subtree: true, attributes: true, characterData: true};

            // Callback function to execute when mutations are observed
            const callback = function (mutationsList, observer) {
              const attemptScroll = () => {
                const elementToScroll = iframeDocument.querySelector(`#${elementId}`);

                if (elementToScroll) {
                  elementToScroll.style.borderColor = '#fd7e14';
                  elementToScroll.scrollIntoView({
                    behavior: "smooth",
                    block: "nearest",
                  });
                  observer.disconnect();
                } else {
                  setTimeout(attemptScroll, 100); // Retry after 100ms if element is not found
                }
              };

              attemptScroll();
            };

            // Create an observer instance linked to the callback function
            const observer = new MutationObserver(callback);

            // Start observing the target node for configured mutations
            observer.observe(iframe.contentWindow.document.body, config);
          }
        },
        destroy: () => {
          if (window.cwFancyboxInit) {
            location.reload();
          }
        },
      },
    });
  }
  handleModuleEdit()
}
