import { checkTableWidth } from '../components/tables';
import {observeElementInViewport} from "../utilities/observe";

export function handlePipeline() {
  const pipeline = document.querySelector('.module-pipeline');
  const pipelineItems = document.querySelectorAll('.pipeline-item');

  if (!pipelineItems || !pipeline) {
    return
  }

  const animation = () => {
    Array.from(pipelineItems).map(elem => {
      let line = elem.querySelector('.line');
      if (line) {
        line.style.width = line.dataset.width + '%';
        line.style.opacity = 1;
      }
    });
  }

  observeElementInViewport(pipeline, (isVisible) => {
    if (isVisible) {
      setTimeout(() => {
        animation();
      }, 400);
    }
  }, { once: true });

  checkTableWidth('.module-pipeline-inner', '.pipeline-container');
  window.onresize = function () { checkTableWidth('.module-pipeline-inner', '.pipeline-container'); };
}
