export function handleProjects() {
  const anchorLinks = document.querySelectorAll('.anchor-link');

  if (!anchorLinks.length) return

  Array.from(anchorLinks).map(link => {
    link.addEventListener('click', (e) => {
      e.preventDefault();
      let elem = document.querySelector(link.querySelector('a').getAttribute('href'));
      elem.scrollIntoView({
        behavior: 'smooth',
      });
    });
  });
}
