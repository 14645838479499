export function handleScrollingMenu() {
  const sections = document.querySelectorAll('.parallax-single-item');
  const menuItems = document.querySelectorAll('.scrolling-menu .menu-item a:not(.edit-page)');
  const hamburgerMenuItems = document.querySelectorAll('#navbarNav .navbar-nav .nav-link');
  const scrollingMenu = document.querySelector('.scrolling-menu');
  const menuOverlay = document.querySelector('.scrolling-menu-overlay');
  const toggleBtn = document.querySelector('.toggle-sidemenu');
  let scrollBottom
  let documentHeight

  if(!scrollingMenu) return;

  let lastActiveIndex = -1;

  function setActiveMenuItem() {
    let activeIndex = 0;

    scrollBottom = window.scrollY + window.innerHeight;
    documentHeight = document.documentElement.scrollHeight;

    sections.forEach((section, index) => {
      if (window.scrollY + 250 >= section.offsetTop) {
        activeIndex = index;
      }
    });

    if (scrollBottom >= documentHeight - 10) {
      activeIndex = sections.length - 1;
    }

    menuItems.forEach((item) => item.parentElement.classList.remove("active"));

    menuItems[activeIndex].parentElement.classList.add("active");

    if (activeIndex !== lastActiveIndex && window.scrollY !== 0) {
      const activeSectionId = sections[activeIndex].id;
      if (activeSectionId) {
        history.replaceState(null, null, `#${activeSectionId}`);
      }

      if(window.location.hash) {
        hamburgerMenuItems.forEach((item) => {item.classList.remove('active')})
        hamburgerMenuItems.forEach((item) => {
          const menuHash = new URL(item.href).hash;
          if(menuHash === `${window.location.hash}`) {
            item.classList.add('active')}})
      }
      lastActiveIndex = activeIndex;
    }
  }

  function scrollToSection(event) {
    const targetId = this.getAttribute('href');
    const targetSection = document.querySelector(targetId);
    if(targetSection) {
      event.preventDefault();
    } else {return;}
    const regularSpacing = 140;
    const extraSpacing = 200;
    const currentScrollY = window.scrollY + regularSpacing
    const isScrollingUp = targetSection.offsetTop < currentScrollY;
    const isFirstSection = Array.from(sections).indexOf(targetSection) === 0;
    const scrollPosition = isFirstSection && isScrollingUp ? targetSection.offsetTop - extraSpacing : targetSection.offsetTop - regularSpacing;

    window.scrollTo({
      top: scrollPosition,
      behavior: 'smooth',
    });
  }

  menuItems.forEach(item => {
    item.addEventListener('click', scrollToSection);
  });

  toggleBtn.addEventListener('click', () => {
    toggleBtn.classList.toggle('open');
    menuOverlay.classList.toggle('active');
  })

  setActiveMenuItem();

  function removeOverlay() {
    scrollingMenu.addEventListener('mouseenter', () => {
      menuOverlay.classList.add('active');
    })

    scrollingMenu.addEventListener('mouseleave', () => {
      menuOverlay.classList.remove('active');
      toggleBtn.classList.remove('open');
    })
  }
  removeOverlay();

  window.addEventListener("scroll", setActiveMenuItem);
}
