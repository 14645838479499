const sharetickersMFN = document.querySelectorAll('.target-ticker-mfn');

export function handleSharetickerMfn() {
  if (!sharetickersMFN.length) return

  Array.from(sharetickersMFN).map(ticker => {
    let type = ticker.dataset.size;
    let baseUrl = ticker.dataset.baseurl;

    fetch(baseUrl+'/wp-json/mfn/shareticker').then(res => {
      return res.json();
    }).then(data => {
      outputHtml(data);
    });

    const up ='<svg xmlns="http://www.w3.org/2000/svg" class="ticker-image" viewBox="0 0 17 10.3"><path d="M1,10.3A.9.9,0,0,1,.3,10a1,1,0,0,1,0-1.4l5-5a1,1,0,0,1,1.4,0L9.3,6.2,13.6,2H9.3a.9.9,0,0,1-1-1,.9.9,0,0,1,1-1h7.1l.3.2h0l.2.3A.6.6,0,0,1,17,1V7.7a1,1,0,1,1-2,0V3.4l-5,5a1,1,0,0,1-1.4,0L6,5.7,1.7,10A.9.9,0,0,1,1,10.3Z" transform="translate(0)" fill="#00f80a"/></svg>';
    const down ='<svg xmlns="http://www.w3.org/2000/svg" class="ticker-image" viewBox="0 0 17 10.3"><path d="M1.7.3,6,4.6,8.6,1.9a1,1,0,0,1,1.4,0l5,5V2.6a1,1,0,0,1,2,0V9.3a.6.6,0,0,1-.1.4c0,.1-.1.2-.2.3h0l-.3.2H9.3a.9.9,0,0,1-1-1,.9.9,0,0,1,1-1h4.3L9.3,4.1,6.7,6.7a1,1,0,0,1-1.4,0l-5-5A1,1,0,0,1,.3.3.9.9,0,0,1,1,0,.9.9,0,0,1,1.7.3Z" transform="translate(0)" fill="#e14d2e"/></svg>';
    const unadjusted ='<svg xmlns="http://www.w3.org/2000/svg" class="ticker-image" viewBox="0 0 17 10.3"><path d="M16.9,4.8l-.2-.3L12,.3a1,1,0,0,0-1.4,0c-.5.4-.5.9,0,1.2l3,2.8H0V6H13.6l-3,2.8c-.5.3-.5.8,0,1.2A1,1,0,0,0,12,10l4.7-4.2c.1-.1.2-.1.2-.3s.1-.2.1-.4A.4.4,0,0,0,16.9,4.8Z" transform="translate(0 0)" fill="#49b8c4"/></svg>';

    const getImage = (value) => {
      if (value > 0)
        return up;
      if (value < 0)
        return down;
      else
        return unadjusted;
    };

    const getColor = (value) => {
      if (value > 0)
        return 'text-success';
      if (value < 0)
        return 'text-info';
      else
        return 'text-danger';
    };

    const outputHtml = data => {

      // Small shareticker
      if (!data.error && type === 'small') {
        ticker.innerHTML = `
        <div class="d-flex align-items-center">
            <p class="ticker-name">${data.stock} (${data.stocks[0].stockSymbol})</p>
            ${getImage(data.stocks[0].deltaToday)}
        </div>
        <div class="ticker-price-container">
            <span class="ticker-price ${getColor(data.stocks[0].deltaTodaySek)}">${data.stocks[0].latestPrice.toFixed(2)} ${data.currency}</span>
            <span>(${data.stocks[0].deltaToday > 0 ? '+' : ''}${+(Math.round(data.stocks[0].deltaToday * 100 + 'e+2')  + 'e-2')}%)</span>
        </div>`;
      }

      // Large shareticker
      if (!data.error && type === 'large') {
        ticker.innerHTML = `
          <div class="left position-relative">
            <p class="ticker-name">${data.stock} (${data.stocks[0].stockSymbol})</p>
            ${getImage(data.stocks[0].deltaToday)}
            <div class="latest-change-datetime">${data.stocks[0].latestDate} ${data.stocks[0].latestTime}</div>
          </div>
          <div class="right">
            <div class="ticker-price ${getColor(data.stocks[0].deltaTodaySek)}">${data.stocks[0].latestPrice.toFixed(2)} ${data.currency}</div>
            <div>(${data.stocks[0].deltaToday > 0 ? '+' : ''}${+(Math.round(data.stocks[0].deltaToday * 100 + 'e+2')  + 'e-2')}%)</div>
          </div>
        `;
      }

      if (data.error) {
        ticker.innerHTML = `<span class="text-danger small">No data from feed</span>`;
      }

    };
  });
}
