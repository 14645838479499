export const handleReportsFilters = () => {
  const handleReportsFilters = () => {
    const yearDropdownItems = document.querySelectorAll('.reports .dropdown-year .dropdown-item');
    const typeDropdownItems = document.querySelectorAll('.reports .dropdown-type .dropdown-item');
    const releases = document.querySelectorAll('.report-row');
    const clearFilterButtons = document.querySelectorAll('.report-clear button');

    let selectedYears = [];
    let selectedTypes = [];

    const filterReleases = () => {
      releases.forEach(report => {
        const yearMatch = selectedYears.length === 0 || selectedYears.includes(report.dataset.reportyear) || selectedYears.includes('/en/investors/?y=all');
        const typeMatch = selectedTypes.length === 0 || selectedTypes.includes(report.dataset.reporttype) || selectedTypes.includes('/en/investors/');

        if (yearMatch && typeMatch) {
          report.style.display = 'block';
        } else {
          report.style.display = 'none';
        }
      });
    };

    const handleDropdownSelection = (dropdownItems, filterType) => {
      dropdownItems.forEach(item => {
        const input = item.querySelector('input');

        input.addEventListener('click', () => {
          const selectedValue = input.value;

          if (input.checked) {
            if (filterType === 'year') {
              if (selectedValue === '/en/investors/?y=all') {
                selectedYears = [];
              } else {
                selectedYears.push(selectedValue);
                selectedYears = selectedYears.filter(year => year !== '/en/investors/?y=all');
              }
            } else if (filterType === 'type') {
              if (selectedValue === 'All types') {
                selectedTypes = [];
              } else {
                selectedTypes.push(selectedValue);
                selectedTypes = selectedTypes.filter(type => type !== 'All types');
              }
            }
          } else {
            if (filterType === 'year') {
              selectedYears = selectedYears.filter(year => year !== selectedValue);
            } else if (filterType === 'type') {
              selectedTypes = selectedTypes.filter(type => type !== selectedValue);
            }
          }

          clearFilterButtons.forEach(button => button.classList.add('d-none'));
          selectedYears.forEach(year => {
            document.querySelector(`.report-clear button[data-clear="${year}"]`).classList.remove('d-none');
          });
          selectedTypes.forEach(type => {
            document.querySelector(`.report-clear button[data-clear="${type}"]`).classList.remove('d-none');
          });

          filterReleases();
        });
      });
    };

    handleDropdownSelection(yearDropdownItems, 'year');
    handleDropdownSelection(typeDropdownItems, 'type');

    clearFilterButtons.forEach(button => {
      button.addEventListener('click', () => {
        const targetToClear = button.dataset.clear;

        if (button.dataset.type === 'year') {
          selectedYears = selectedYears.filter(year => year !== targetToClear);
        } else {
          selectedTypes = selectedTypes.filter(type => type !== targetToClear);
        }

        button.classList.add('d-none');

        const inputToUncheck = document.querySelector(`.reports input[value="${targetToClear}"]`);
        if (inputToUncheck) {
          inputToUncheck.checked = false;
        }

        filterReleases();
      });
    });
  };

  handleReportsFilters();
};
