import { checkTableWidth } from '../components/tables';
import {observeElementInViewport} from "../utilities/observe";

export function handlePipelinev2() {
  const pipeline = document.querySelector('.module-pipeline-v2');
  const pipelineItems = document.querySelectorAll('.pipelinev2-item');

  if (!pipelineItems || !pipeline) {
    return
  }

  const animation = () => {
    Array.from(pipelineItems).map(elem => {
      let line = elem.querySelector('.line');

      // lineWidthHelper - check ModuleTypePipelinev2Composer progress_line_width_helper = ((number of cols - item x pos) / item width - 1) * grid gap - arrow width
      if (line) {
        line.style.width = 'calc(' + line.dataset.width + '% + '+ line.dataset.lineWidthHelper +'px)';
        line.style.opacity = 1;
      }
    });
  }

  observeElementInViewport(pipeline, (isVisible) => {
    if (isVisible) {
      setTimeout(() => {
        animation();
      }, 400);
    }
  }, { once: true });

  checkTableWidth('.pipelinev2-container', '.pipelinev2-container-inner');
  window.onresize = function () { checkTableWidth('.pipelinev2-container', '.pipelinev2-container-inner'); };
}
