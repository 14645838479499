export const handleReleasesFilters = () => {
  // on load show data-count length items
  const releasesContainer = document.querySelectorAll('.module-releases');

  if (!releasesContainer.length) return;

  releasesContainer.forEach((container) => {
    let count = container.querySelector('.article-container').dataset.count;
    let releases = container.querySelectorAll('article');
    releases.forEach((element, index) => {
      if (index >= count) {
        element.style.display = 'none';
      }
    });    
  });

  // Filtering
  const handleReleasesFiltering = () => {
    let dropdownItems = document.querySelectorAll('.module-releases .dropdown-item');

    if (!dropdownItems.length) return;

    let releases = document.querySelectorAll('article.post');
    const clearFilterButtons = document.querySelectorAll('.module-releases .clear-filters button')

    let selectedYears = [];

    dropdownItems.forEach(item => {
      const input = item.querySelector('input')

      input.addEventListener('click', () => {
        let selectedYear = input.value;

        if (selectedYears.includes(selectedYear)) {
          selectedYears = selectedYears.filter(year => year !== selectedYear);
          document.querySelector(`.clear-filters button[data-clear="${selectedYear}"]`).classList.add('d-none')
        } else {
          selectedYears.push(selectedYear);
          document.querySelector(`.clear-filters button[data-clear="${selectedYear}"]`).classList.remove('d-none')
        }

        if (selectedYears.length === 0) {
          releases.forEach(release => {
            release.style.display = 'block';
          });
        } else {
          releases.forEach(release => {
            if (selectedYears.includes(release.dataset.year)) {
              release.style.display = 'block';
            } else {
              release.style.display = 'none';
            }
          });
        }
      });
    });

    clearFilterButtons.forEach(item => {
      item.addEventListener('click', () => {
        item.classList.add('d-none')
        const targetToClear = item.dataset.clear

        releases.forEach(release => {
          if (release.dataset.year === targetToClear) {
            selectedYears = selectedYears.filter(year => year !== targetToClear);
            release.style.display = 'none';
            document.querySelector(`input[value="${targetToClear}"]`).checked = false
          }
        });

        if (selectedYears.length === 0) {
          releases.forEach(release => {
            release.style.display = 'block';
          });
        }
      })
    })
  }
  handleReleasesFiltering()
}
