// People

function handlePeople() {
  const peopleExpandable = document.querySelectorAll('.type-people:has(.people-top)');

  if (!peopleExpandable.length) return

  peopleExpandable.forEach(typePeople => {
    const container = typePeople.querySelector('.contact-list-container')
    const contacts = typePeople.querySelectorAll('.contact')

    function addDivAfterIndex(index, nrItems) {
      const existingCloned = container.querySelector('.cloned-content');
      if (existingCloned) existingCloned.remove();

      const clonedDiv = document.createElement('div');
      clonedDiv.classList.add('cloned-content');
      const groupIndex = Math.floor(index / nrItems);
      const insertIndex = (groupIndex + 1) * nrItems - 1;
      container.insertBefore(clonedDiv, contacts[insertIndex + 1]);

      const content = contacts[index].querySelector('.contact-content').cloneNode(true);
      clonedDiv.appendChild(content);

      setTimeout(() => {        
        clonedDiv.style.transform = 'translateX(0)';
      }, 0);

      const closeButton = content.querySelector('.btn-close');

      document.addEventListener('keydown', function (e) {
        const code = e.key;
        if (code === 'Escape') {
          setTimeout(() => {            
            clonedDiv.style.transform = 'translateX(100%)';
            document.body.classList.remove('mobile-menu-visible');
            document.body.classList.remove('fixed');
          }, 0);
        }
      });

      document.addEventListener('click', function (event) {  
        const existingCloned = container.querySelector('.cloned-content');
        if(existingCloned && event.target.matches('.sliding-panel-fade')) {
          setTimeout(() => {            
            clonedDiv.style.transform = 'translateX(100%)';
            document.body.classList.remove('mobile-menu-visible');
            document.body.classList.remove('fixed');
          }, 0);
        }
      });

      if (closeButton) {
        closeButton.addEventListener('click', () => {
          // clonedDiv.remove();
          setTimeout(() => {            
            clonedDiv.style.transform = 'translateX(100%)';
            document.body.classList.remove('mobile-menu-visible');
            document.body.classList.remove('fixed');
            contacts[index].classList.remove('active');
          }, 0);
        });
      }
    }

    contacts.forEach((singleContact, index) => {
      let readMoreBtn = singleContact.querySelector('.read-more');
      readMoreBtn.addEventListener('click', () => {
        if (singleContact.classList.contains('active')) {
          // If the clicked item is already active, close it
          const existingCloned = container.querySelector('.cloned-content');
          if (existingCloned) existingCloned.remove();
          singleContact.classList.remove('active');
          container.classList.remove('has-active');
        } else {
          // Otherwise, proceed with the existing functionality
          contacts.forEach(contact => {
            contact.classList.remove('active');
          });

          document.body.classList.add('fixed');
          document.body.classList.add('mobile-menu-visible');
          singleContact.classList.add('active');

         
          addDivAfterIndex(index, 1);
        }
      });
    });
  })
}

export {handlePeople}

