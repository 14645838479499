export const styles = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff",
      },
    ],
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd",
      },
    ],
  },
  {
    "featureType": "administrative.locality",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#912727",
      },
    ],
  },
  {
    "featureType": "landscape",
    "stylers": [
      {
        "color": "#abb7c4",
      },
    ],
  },
  {
    "featureType": "poi",
    "stylers": [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee",
      },
    ],
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575",
      },
    ],
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5",
      },
    ],
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e",
      },
    ],
  },
  {
    "featureType": "road",
    "stylers": [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff",
      },
    ],
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575",
      },
    ],
  },
  {
    "featureType": "road.highway",
    "stylers": [
      {
        "weight": 4.5,
      },
    ],
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dadada",
      },
    ],
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161",
      },
    ],
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e",
      },
    ],
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5",
      },
    ],
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee",
      },
    ],
  },
  {
    "featureType": "water",
    "stylers": [
      {
        "color": "#ffffff",
      },
      {
        "visibility": "on",
      },
    ],
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff",
      },
    ],
  },
  {
    "featureType": "water",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e",
      },
    ],
  },
]
