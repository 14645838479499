export function handlePortfolio() {
  const portfolioSlider = document.querySelector('.module-slidergb');

  if (!portfolioSlider) return;

  const slides = portfolioSlider.querySelectorAll('.swiper-slide:not(:first-of-type)');
  const prevButton = portfolioSlider.querySelector('.swiper-button-prev');
  const nextButton = portfolioSlider.querySelector('.swiper-button-next');
  const pagination = portfolioSlider.querySelector('.custom-pagination');
  const wrapper = portfolioSlider.querySelector('.swiper');
  const outerWrapper = portfolioSlider.querySelector('.swiper-outer-container');
  const customBullets = portfolioSlider.querySelector('.custom-bullets');
  const totalSlides = slides.length;
  const amountPerView = 1; //Desktop only
  const slideDelay = 400;
  let slideTimeout = true;
  let previousIndex;
  let currentIndex = 0;
  
  function centerOpenBtn() {
    slides.forEach((slide) => {
      const logo = slide.querySelector('.gd_slide_logo');
      const button = slide.querySelector('.trigger-modal');
      const parent = slide.querySelector('.module-content');  
      const logoRect = logo?.getBoundingClientRect();
      const parentRect = parent?.getBoundingClientRect();
      const logoCenterX = logoRect?.left + (logoRect?.width / 2) - parentRect?.left 
      if(button) {
        button.style.left = (logoCenterX - button.offsetWidth / 2) + 'px';
      }
    })
  }

  function isDesktop() {
    return window.innerWidth >= 1024;
  }

  function isMobile() {
    return window.innerWidth <= 550;
  }

  function updateSlider() {
    const marginLeft = Math.abs(parseFloat(getComputedStyle(slides[1]).marginLeft))
    const slideWidth = slides[1].getBoundingClientRect().width - marginLeft;
    const maxIndex = totalSlides;
    let offset;

    if (currentIndex > maxIndex) {
      currentIndex = maxIndex;
    }

    if (currentIndex < 0) {
      currentIndex = 0;
    }

    
    if (isMobile()) {
      // 2nd slide is more left than following ones
      let mobileSlideWidth = slides[2].getBoundingClientRect().width - Math.abs(parseFloat(getComputedStyle(slides[2]).marginLeft));
      let mobileSlideWidth2 = slides[1].getBoundingClientRect().width - Math.abs(parseFloat(getComputedStyle(slides[1]).marginLeft));
      offset = -currentIndex * mobileSlideWidth;

      if(currentIndex === 1) {
        offset = -currentIndex * mobileSlideWidth2 - 70;
        wrapper.style.transform = `translateX(${offset}px)`;
      } else if(currentIndex === totalSlides) {    
        offset = -currentIndex * mobileSlideWidth - 50; 
        wrapper.style.transform = `translateX(${offset}px)`;   
      } else if (currentIndex === 0 && previousIndex === 1) {
        wrapper.style.transform = `translateX(0px)`;
      } else {
        offset = -currentIndex * mobileSlideWidth - 20; 
        wrapper.style.transform = `translateX(${offset}px)`;
      }
    } else {
      if (currentIndex >= totalSlides - amountPerView) {
        offset = -((totalSlides - amountPerView) * slideWidth);
      } else {
        offset = -currentIndex * slideWidth;
      }
      wrapper.style.transform = `translateX(${offset}px)`;
    }

    //transform to finish
    setTimeout(() => {
      updatePagination();
      updateButtons();
      updateActiveBullet();      
    }, 300);
  }

  function countVisibleSlides() {
    let visibleSlidesCount = 0;

    slides.forEach((slide) => {
      if(isSlideVisible(slide)){
        visibleSlidesCount++
      }
    })

    return visibleSlidesCount;
  }

  function updatePagination() {
    const visibleCount = countVisibleSlides();
    if(isDesktop()) {
      pagination.innerHTML = `Showing ${visibleCount} of ${totalSlides} holdings`;
    } else {
      if(currentIndex === 0) {
        pagination.innerHTML = `${totalSlides} holdings`;
      } else {
        pagination.innerHTML = `Showing ${currentIndex} of ${totalSlides} holdings`;
      }
    }
  }

  function showNextSlide() {
    if (!slideTimeout) return;

    slideTimeout = false

    if(isDesktop()) {
      if (currentIndex < totalSlides - amountPerView) {
        currentIndex += amountPerView;
      } else {
        currentIndex = totalSlides - amountPerView;
      }
    } else {
      if (currentIndex < totalSlides) {
        currentIndex++;
      }
    }

    updateSlider();

    setTimeout(function() {
      slideTimeout = true;
    }, slideDelay);
  }

  function showPrevSlide() {
    previousIndex = currentIndex;
    if(isDesktop()) {
      if (currentIndex > 0) {
        currentIndex -= amountPerView;
        if (currentIndex < 0) {
          currentIndex = 0;
        }
      }
    } else {
      if (currentIndex > 0) {
        currentIndex--;
      }
    }

    updateSlider();
  }

  function updateButtons() {
    const lastSlideRect = slides[slides.length - 1].getBoundingClientRect();
    const wrapperRect = outerWrapper.getBoundingClientRect();
    const isLastSlideVisible = () => {
      if(lastSlideRect.right >= wrapperRect.left && lastSlideRect.right <= wrapperRect.right){
        nextButton.classList.add('disabled')
      } else {
        nextButton.classList.remove('disabled')
      }
    }

    isLastSlideVisible();

    prevButton.classList.toggle('disabled', currentIndex === 0);
  }

  function createBullets() {
    customBullets.innerHTML = '';
    for (let i = 0; i < totalSlides; i++) {
      const bullet = document.createElement('span');
      bullet.classList.add('bullet');
      customBullets.appendChild(bullet);
    }
 
    updateActiveBullet();
  }

  function isSlideVisible(slide) {
    const rect = slide?.getBoundingClientRect();
    const wrapperRect = outerWrapper.getBoundingClientRect();

    if(rect) {
      return (rect.left >= wrapperRect.left && rect.left <= wrapperRect.right && rect.right >= wrapperRect.left && rect.right <= wrapperRect.right);
    }
  }

  function updateActiveBullet() {
    const bullets = customBullets.querySelectorAll('.bullet');
    bullets.forEach(bullet => bullet.classList.remove('active'));
    slides.forEach(slide => slide.classList.remove('hoverable'));

    if(isDesktop()){
      slides.forEach((slide, index) => {
        if (isSlideVisible(slide)) {
          bullets[index]?.classList.add('active');
        }

        if (isSlideVisible(slide)) {
          slides[index].classList.add('hoverable');
        }
      });
    } else {
      if(currentIndex === 0) {
        bullets[0].classList.add('active');
      } else {
        bullets[currentIndex - 1].classList.add('active');
      }
    }
  }

  nextButton.addEventListener('click', showNextSlide);
  prevButton.addEventListener('click', showPrevSlide);

  const triggerModalBtn = document.querySelectorAll('.trigger-modal');
  const logos = document.querySelectorAll('.gd_slide_logo');
  const modal = portfolioSlider.querySelector('.modal');
  const modalBody = modal.querySelector('.modal-body');
  const closeBtn = modal.querySelector('.btn-close');

  const triggerModal = [...triggerModalBtn, ...logos];

  triggerModal.forEach((button) => {
    button.addEventListener('click', (event) => {
      const slideElement = event.target.closest('.module-content');
      const modalContent = slideElement.querySelector('.gd_slide_modal_text')?.innerHTML;
      modal.classList.add('show');
      modalBody.innerHTML = modalContent || '';
      document.body.classList.add('mobile-menu-visible');
    });
  });

  closeBtn.addEventListener('click', () => {
    modal.classList.remove('show');
    document.body.classList.remove('mobile-menu-visible');
  });

  document.addEventListener('keydown', function (e) {
    if (e.key === 'Escape') {
      modal.classList.remove('show');
      document.body.classList.remove('mobile-menu-visible');
    }
  });

  window.addEventListener('resize', function () {
    updateButtons();
    updateActiveBullet();
  });

  createBullets();
  updatePagination();
  updateButtons();
  centerOpenBtn();
}
