import Dropdown from 'bootstrap/js/dist/dropdown';

export function handleDropdowns() {
  // Open dropdown on hover
  const dropdownHover = () => {
    const dropdowns = document.querySelectorAll('.dropdown-hover');

    if (!dropdowns.length) return

    dropdowns.forEach(dropdown => {
      let langSwitcher = dropdown.querySelector('button');
      let langSwitcherInstance = new Dropdown(langSwitcher);
      dropdown.addEventListener('mouseenter', function () {
        langSwitcherInstance.show();
      });
      dropdown.addEventListener('mouseleave', function () {
        langSwitcherInstance.hide();
        langSwitcher.blur();
      });
    })
  }
  dropdownHover()
}
