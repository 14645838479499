export const WpCurrentClasses = ['current-post-ancestor',
  'current-page-ancestor',
  'current-menu-ancestor',
  'current-menu-parent',
  'current-page-parent',
  'current_page_parent',
  'current-menu-item',
  'current_page_item',
  'current_page_ancestor',
  'current-category-ancestor',
];

export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};
