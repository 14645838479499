export const handleImagebankFilters = () => {
  const imagebankContainer = document.querySelectorAll('.imagebank-list');
  let selectedCategories = [];
  let defaultShow = false;
  let defaultCategory;

  if (!imagebankContainer.length) return;

  imagebankContainer.forEach((container) => {
    let images = container.querySelectorAll('.imagebank-item');

    images.forEach((image) => {
      if (image.classList.contains('visible-initially')) {
        image.style.display = 'block';
        defaultCategory = image.dataset.category;
        defaultShow = true;
      } else {
        image.style.display = 'none';
      }
    });

    if(!defaultShow) {
      images.forEach((element) => {
        element.style.display = 'block';
      }); 
    }
  });
  
  if(defaultShow && defaultCategory) {
    selectedCategories.push(defaultCategory);
    document.querySelector(`.clear-filters button[data-clear="${defaultCategory}"]`).classList.remove('d-none');
    document.querySelector(`input[value="${defaultCategory}"]`).checked = true;
  }

  // Filtering
  const handleImagebankFiltering = () => {
    let dropdownItems = document.querySelectorAll('.imagebank-dropdown .dropdown-item');

    if (!dropdownItems.length) return;

    let images = document.querySelectorAll('.imagebank-item');
    const clearFilterButtons = document.querySelectorAll('.imagebank-clear-filters .clear-filters button')

    dropdownItems.forEach(item => {
      const input = item.querySelector('input')
      if (input && selectedCategories.includes(input.value)) {
        item.classList.add('active');
      } else {
        item.classList.remove('active');
      }
      input.addEventListener('click', () => {
        let selectedCategory = input.value;

        if (selectedCategories.includes(selectedCategory)) {
          selectedCategories = selectedCategories.filter(category => category !== selectedCategory);
          document.querySelector(`.clear-filters button[data-clear="${selectedCategory}"]`).classList.add('d-none')
          input.closest('li').classList.remove('active');
        } else {
          selectedCategories.push(selectedCategory);
          input.closest('li').classList.add('active');
          document.querySelector(`.clear-filters button[data-clear="${selectedCategory}"]`).classList.remove('d-none')
        }

        if (selectedCategories.length === 0) {
          images.forEach(image => {
            image.style.display = 'block';
          });
        } else {
          images.forEach(image => {
            if (selectedCategories.includes(image.dataset.category)) {
              image.style.display = 'block';
            } else {
              image.style.display = 'none';
            }
          });
        }
      });
    });

    clearFilterButtons.forEach(item => {
      item.addEventListener('click', () => {
        item.classList.add('d-none')
        const targetToClear = item.dataset.clear

        images.forEach(image => {
          if (image.dataset.category === targetToClear) {
            selectedCategories = selectedCategories.filter(category => category !== targetToClear);
            image.style.display = 'none';
            document.querySelector(`input[value="${targetToClear}"]`).checked = false
            document.querySelector(`input[value="${targetToClear}"]`).closest('li').classList.remove('active');
          }
        });

        if (selectedCategories.length === 0) {
          images.forEach(image => {
            image.style.display = 'block';
          });
        }
      })
    })
  }
  handleImagebankFiltering()
}
