import TomSelect from 'tom-select';

export function handleDisclaimer() {
  // Country select
  const handleCountrySelect = () => {
    let selects = document.querySelectorAll('.country-select select');

    if (!selects.length) return

    let form = document.getElementById('country-select')

    selects.forEach((select) => {
      new TomSelect(select);
    });

    if (form) {
      form.addEventListener('submit', function (event){

        form.classList.add('was-validated')

        if (!form.checkValidity()) {
          event.preventDefault()
          event.stopPropagation()
        }

      }, false)
    }
  }
  handleCountrySelect()

  // Next sections
  const handleShowNextSection = () => {
    let nextSectionButton = document.querySelectorAll('.show-next-section');
    if (nextSectionButton.length) {
      Array.from(nextSectionButton).map(btn => {
        btn.addEventListener('click', function (e) {
          e.preventDefault();
          document.querySelector('.disclaimer-additional-section#section-'+btn.dataset.trigger).classList.remove('d-none');
        })
      })
    }
  }
  handleShowNextSection()
}
